<template>
  <div class="home">
    <el-container class="normalCon">
      <el-header>
        <div class="header">
          <div class="deviceBack">
            <i class="el-icon-arrow-left" @click="back()"></i>
          </div>
          <div class="header_title">通知公告</div>
        </div>
      </el-header>
      <el-main style="background-color: #f1f1f1;">
        <div class="announcePart">
          <div
            class="announceList"
            v-for="(item, index) in orderList"
            :key="index"
            @click="goToDetails(item.id)"
          >
            <div class="announceTop">{{ item.title }}</div>
            <div class="announceCon">{{ item.description }}</div>
            <div class="announceBottom">发布时间:{{ item.publishTime }}</div>
          </div>
        </div>
      </el-main>
      <el-footer>
        <el-pagination
          background
          class="footerPagination"
          layout="prev, pager, next"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :total="totalPage"
        />
      </el-footer>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      totalPage: 0,
      pageIndex: 1,
      pageLimit: 10,
      username: null, // 用户名称
      orderList: []
    }
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    getDataList() {
      this.$http.httpGet(`/cms/appAnnouncement/list`, {
        page: this.pageIndex,
        limit: this.pageLimit,
      }).then(data =>{
        if (data && data.code === 0) {
          if (this.pageIndex > 1) {
            this.orderList = this.orderList.concat(data.page.list);
          } else {
            this.orderList = data.page.list;
          }
          this.totalPage = data.page.totalPage;
        } else {
          this.orderList = [];
          this.totalPage = 0;
        }
      })
    },
    // 跳转到预约详情
    goToDetails(id) {
      this.$router.replace({ name: "announceDetail", query: {id: id} });
    },
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    back() {
      this.$router.replace("Home");
    }
  },
}
</script>

<style scoped lang="scss">
.home {
  height: 100%;
  .el-container {
    height: 100%;
    .el-main {
      padding: 0 !important;
      .announcePart {
        position: relative;
        display: block;
        .announceList {
          position: relative;
          overflow: hidden;
          background-color: #fff;
          .announceTop {
            font-size: 16px;
            padding: 8px 10px;
            font-weight: 600;
            line-height: 1.4;
            max-height: 40px;
            letter-spacing: 1px;
            word-break: break-all;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            border-bottom: 1px #efefef solid;
          }
          .announceCon {
            font-size: 13px;
            padding: 15px 10px 0;
            text-indent: 2em;
            line-height: 1.6;
            max-height: 52px;
            word-break: break-all;
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .announceBottom {
            margin: 15px 0 0;
            border-top: 1px #efefef solid;
            font-size: 13px;
            color: #000;
            line-height: 1.6;
            padding: 10px;
            text-align: right;
          }
        }
      }
    }
    .el-footer {
      .footerPagination {
        position: relative;
        padding: 13px 0 14px;
        margin: 0 auto;
        display: block;
        text-align: center;
      }
    }
  }
}
</style>
